.lia-alert-container.lia-alert-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  padding: 13px 15px;
  border: 1px solid var(--lia-bs-gray-500);
  border-left: 5px solid var(--lia-bs-gray-500);
  margin-top: 10px;

  &-info {
    border-color: var(--lia-bs-info);
  }
  &-warning {
    border-color: var(--lia-bs-warning);
  }
  &-danger {
    border-color: var(--lia-bs-danger);
  }
  &-success {
    border-color: var(--lia-bs-success);
  }

  .lia-alert-icon-wrap {
    display: flex;
    align-items: center;
    margin: 3px 10px 0 0;
  }

  .lia-alert-message {
    font-size: var(--lia-bs-small-font-size);
    color: var(--lia-bs-gray-900);
    overflow-wrap: break-word;
    word-break: break-word;
  }
}
