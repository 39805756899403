@import '@aurora/shared-client/styles/_variables.pcss';
.lia-form-actions-outer-container {
  margin-top: 30px;

  &-sticky {
    padding: 12px 0;
    background-color: var(--lia-bs-white);
    box-shadow: var(--lia-bs-box-shadow-sm);
    border-top: 1px solid var(--lia-bs-border-color);

    @media (--lia-breakpoint-up-sm) {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
    @media (--lia-breakpoint-down-xs) {
      padding: 20px 15px;
      border: 1px solid var(--lia-bs-border-color);
      margin: auto calc(var(--lia-grid-gutter-width-xs) * 0.5)
        calc(var(--lia-grid-gutter-width-xs) * 0.5);
      border-radius: var(--lia-rte-border-radius);
      box-shadow: none;
    }
  }
}

.lia-form-actions-inner-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.lia-form-actions-align {
    &-left {
      justify-content: flex-start;
    }
    &-right {
      justify-content: flex-end;
    }
    &-center {
      justify-content: center;
    }
    &-stretch {
      justify-content: stretch;
    }
    &-full-width .lia-form-actions {
      flex-direction: column;
    }
    &-right,
    &-center {
      .lia-form-actions {
        flex-direction: row-reverse;
      }
    }
    &-stretch,
    &-full-width {
      .lia-form-actions {
        flex-basis: 100%;

        .lia-form-actions-btn {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &-sticky {
    max-width: var(--lia-container-max-width);

    @media (--lia-breakpoint-down-xs) {
      padding: 0;
    }
  }

  .lia-form-actions.lia-form-actions {
    display: flex;
    gap: 10px;

    &-sticky {
      @media (--lia-breakpoint-down-xs) {
        flex-direction: column-reverse;
        width: 100%;
        gap: 20px;
      }
    }
  }
}
