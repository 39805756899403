@import '@aurora/shared-client/styles/_variables.pcss';

.lia-fields-wrapper.lia-fields-wrapper {
  margin-bottom: 30px;
  flex-grow: 1;

  @media (--lia-breakpoint-down-xs) {
    margin-bottom: 20px;
  }
}

.lia-form-row,
.lia-form-field {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }
}

.lia-sticky-row-form-group {
  &:last-child {
    margin-bottom: 30px;
  }
}

.lia-form-field-group-view-variant-card {
  .lia-form-field {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.lia-form-field-group-divider:not(:last-child) {
  &:after {
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: var(--lia-bs-border-color);
  }
}

.lia-form-title {
  margin-bottom: 25px;
}
