@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-accordion {
  display: flex;
  flex-direction: column;

  &:has(.lia-accordion-toggle:focus-visible) {
    @include default-outline();
  }

  &:focus-visible {
    .lia-accordion-icon-wrap {
      background-color: var(--lia-bs-gray-200);
    }
  }

  &-header.lia-accordion-header {
    border-radius: var(--lia-rte-border-radius);
    border: none;
    display: grid;
    margin: 0;
    padding: 0;
  }

  &-toggle.lia-accordion-toggle {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
      'title icon'
      'subtext subtext';
    font-size: var(--lia-bs-font-size-base);
    color: var(--lia-bs-body-color);
    background-color: transparent;
    text-align: left;
    border: 0;
    cursor: pointer;

    &:hover {
      .lia-accordion-icon-wrap {
        background-color: var(--lia-bs-gray-200);
      }
    }

    &.lia-accordion-is-open {
      .lia-accordion-icon {
        transform: rotate(90deg);
      }
      &.lia-accordion-has-subtext {
        padding-bottom: 4px;

        .lia-accordion-subtext {
          display: block;
        }
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &-title {
    grid-area: title;
  }
  &-icon-wrap {
    grid-area: icon;
  }
  &-subtext.lia-accordion-subtext {
    grid-area: subtext;
    display: none;
    margin-top: 5px;
    margin-bottom: 0;
  }
  &-icon {
    transition: transform var(--lia-timing-normal) var(--lia-timing-function);
  }
  &-content {
    padding: 16px 15px 30px;
  }
}

.lia-fieldset {
  margin-bottom: 20px;
}

.lia-legend {
  display: block;
  margin-bottom: 10px;
}

.lia-description {
  display: block;
  margin-bottom: 10px;
}

.lia-accordion-has-focus {
  outline: var(--lia-outline-color) solid 3px;
  outline-offset: 0;
}
